import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate
} from "react-router-dom";

import './App.css';
import Home from './Home'
const GameComponent = React.lazy(() => import('./GameComponent'));
const GameComponent2 = React.lazy(() => import('./GameComponent2'));
const GameComponent3 = React.lazy(() => import('./GameComponent3'));


function App() {
  let navigate = useNavigate();
  function getNavFontSize() {
    if (window.innerWidth > 800) return '25px'; 
    if (window.innerWidth > 500) return '20px';
    return '10px';
  }
    

  function changeLocation(placeToGo){
      navigate(placeToGo, { replace: true });
      window.location.reload();
  }
  return (
    <div className="App" >
      <div style={{backgroundColor: 'black'}}>
        <nav>
          <ul style={{display: 'flex', flexDirection: 'row', margin: '0'}}>
            <li style={{padding: '2.5%', fontSize: getNavFontSize()}}>
              <Link to="/" style={{color: '#a200ff'}}>[Home]</Link>
            </li>
            <li style={{padding: '2.5%', fontSize: getNavFontSize(), color: '#a200ff'}}>
              <Link to="https://blog.judecapachietti.com" style={{color: '#a200ff'}}>[Blog]</Link>
            </li>
            <li style={{padding: '2.5%', fontSize: getNavFontSize()}}>
              <Link to="/game" style={{color: '#a200ff'}} onClick={() => changeLocation('/game')}>[Game]</Link>
            </li>
            <li style={{padding: '2.5%', fontSize: getNavFontSize()}}>
              <Link to="/game2" style={{color: '#a200ff'}} onClick={() => changeLocation('/game2')}>[Game2]</Link>
            </li>
            <li style={{padding: '2.5%', fontSize: getNavFontSize()}}>
              <Link to="/game3" style={{color: '#a200ff'}} onClick={() => changeLocation('/game3')}>[Game3]</Link>
            </li>
          </ul>
        </nav>

        {/* A <Routes> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes>
          <Route path="/game" tabIndex="0" element={
            <React.Suspense fallback={<div></div>}>
              <GameComponent />
            </React.Suspense>
          }>
          </Route>
          <Route path="/game2" tabIndex="0" element={
            <React.Suspense fallback={<div></div>}>
              <GameComponent2 />
            </React.Suspense>
          }>
          </Route>
          <Route path="/game3" tabIndex="0" element={
            <React.Suspense fallback={<div></div>}>
              <GameComponent3 />
            </React.Suspense>
          }></Route>
          <Route path="/" element={
            <Home />
          }>
          </Route>
        </Routes>
      </div>
    </div>
  );
}

export default App;
