import React from "react";

import JudeCapachietti from './JudeCapachietti.jpg';
import './App.css';

function Home() {

  return (
    <div className="App">
      <header className="App-header">
        <h1>Jude Capachietti</h1>
        <img src={JudeCapachietti} className="judeImage" alt="logo" />
        <h5>Welcome to my personal website!</h5>
        <p style={{width: '60%', textAlign: 'left'}}>
          I am an avid programmer and have been coding for over half of my life. 
          I work in data and have created freelance web apps.
          I value intentional simplicity and quality in design as well as collaborating with great folks.
          I am always learning new things and I don't shy away from any challenge, 
          whether that be: front-end, back-end, game development, ML, ... anything.
        </p>
        <h3>Check out some of my favorite side projects here:</h3>
        <ul style={{ maxWidth: "80%", paddingInlineStart: '0'}}>

          <li className="App-link-text">
            <a className="App-link" rel="noreferrer" target="_blank" href="https://www.wordlehelpertool.com" style={{marginRight: '5px'}}>
              [Link]
            </a>
            Wordle Game Helper (created with friend)
          </li>

          <li className="App-link-text">
            <a className="App-link" rel="noreferrer" target="_blank" href="https://wasm.judecapachietti.com/SandGame/game.html" style={{marginRight: '5px'}}>
              [Link]
            </a>
            WASM Sand game created from scratch in C <a rel="noreferrer" target="_blank" href="https://github.com/jude253/SandGame">(Source Code)</a>
          </li>

          <li className="App-link-text">
            <a className="App-link" rel="noreferrer" target="_blank" href="https://wasm.judecapachietti.com/FpsDemo/game.html" style={{marginRight: '5px'}}>
              [Link]
            </a>
            WASM Doom-style game demo created from scratch in C <a rel="noreferrer" target="_blank" href="https://github.com/jude253/CFpsGame">(Source Code)</a>
          </li>

          <li className="App-link-text">
            <a className="App-link" rel="noreferrer" target="_blank" href="https://wasm.judecapachietti.com/word-tetris/index.html" style={{marginRight: '5px'}}>
              [Link]
            </a>
            Tetris Word Game Python WASM Demo
          </li>

          <li className="App-link-text">
            <a className="App-link" rel="noreferrer" target="_blank" href="https://wasm.judecapachietti.com/" style={{marginRight: '5px'}}>
              [Link]
            </a>
            A WASM bubble demo
          </li>
          
          <li className="App-link-text">
            <a className="App-link" rel="noreferrer" target="_blank" href="https://finanalysis.io/" style={{marginRight: '5px'}}>
              [Link]
            </a>
            Financial WebApp Tool using Python & Streamlit (shut down to save ~$120/month-- I can redeploy if needed)
          </li>

          <li className="App-link-text">
            <a className="App-link" rel="noreferrer" target="_blank" href="https://www.wordhelper.tools" style={{marginRight: '5px'}}>
              [Link]
            </a>
            General NYT Word Game Helper
          </li>

          <h3>Or my GitHub:</h3>

          <li className="App-link-text">
            <a className="App-link" rel="noreferrer" target="_blank" href="https://github.com/jude253" style={{marginRight: '5px'}}>
              [Link]
            </a>
            GitHub
          </li>
          
        </ul>
        
        <br/>
      </header>
    </div>
  );
}

export default Home;
