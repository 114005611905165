import React, { StrictMode } from "react";
import { Amplify, Analytics } from "aws-amplify";
import ReactDOM from "react-dom";
import App from "./App.js";
import {
    BrowserRouter as Router
  } from "react-router-dom";

Amplify.configure({

});

// enable page view analytics: https://docs.amplify.aws/lib/analytics/autotrack/q/platform/js/#page-view-tracking
Analytics.autoTrack('pageView', {
    enable: true,
    eventName: 'pageView',
    attributes: {
        pathname: window.location.pathname
    },
    type: 'SPA',
    provider: 'AWSPinpoint',
    getUrl: () => {
      return window.location.origin + window.location.pathname;
    }
  });

ReactDOM.render(
<StrictMode>
    <Router>
        <App />
    </Router>
</StrictMode>
, document.getElementById("root")
);